// Generated by Framer (4991a04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AI3YpC1Td", "PsOEGcEJk"];

const serializationHash = "framer-9jnnK"

const variantClassNames = {AI3YpC1Td: "framer-v-7105th", PsOEGcEJk: "framer-v-i3xfyc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "AI3YpC1Td", Mobile: "PsOEGcEJk"}

const getProps = ({height, id, text, width, ...props}) => { return {...props, jYPrNqwFr: text ?? props.jYPrNqwFr ?? "© Implant Ninja 2025 | Contact Us | implantninja@gmail.com • 209-242-2977", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "AI3YpC1Td"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jYPrNqwFr, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AI3YpC1Td", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-7105th", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"AI3YpC1Td"} ref={ref ?? ref1} style={{backgroundColor: "rgb(34, 34, 34)", ...style}} {...addPropertyOverrides({PsOEGcEJk: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-8ebb4457-384c-4a88-b9cb-52e778b1464e, rgb(230, 230, 230)))"}}>© Ninja Implants 2024 | Reach Out | ninjaimplants@gmail.com • 209-242-2977</motion.p></React.Fragment>} className={"framer-1iksmij"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"DrIVxiNWk"} style={{"--extracted-r6o4lv": "var(--token-8ebb4457-384c-4a88-b9cb-52e778b1464e, rgb(230, 230, 230))"}} text={jYPrNqwFr} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({PsOEGcEJk: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-8ebb4457-384c-4a88-b9cb-52e778b1464e, rgb(230, 230, 230)))"}}>© Implant Ninja 2023 | Contact Us | implantninja@gmail.com • 209-242-2977</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9jnnK.framer-8csgfn, .framer-9jnnK .framer-8csgfn { display: block; }", ".framer-9jnnK.framer-7105th { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 50px; position: relative; width: 1200px; }", ".framer-9jnnK .framer-1iksmij { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-9jnnK.framer-v-i3xfyc.framer-7105th { width: 808px; }", ".framer-9jnnK.framer-v-i3xfyc .framer-1iksmij { flex: 1 0 0px; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 121
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"PsOEGcEJk":{"layout":["fixed","auto"]}}}
 * @framerVariables {"jYPrNqwFr":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerc9Vcnnpth: React.ComponentType<Props> = withCSS(Component, css, "framer-9jnnK") as typeof Component;
export default Framerc9Vcnnpth;

Framerc9Vcnnpth.displayName = "Footer";

Framerc9Vcnnpth.defaultProps = {height: 121, width: 1200};

addPropertyControls(Framerc9Vcnnpth, {variant: {options: ["AI3YpC1Td", "PsOEGcEJk"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, jYPrNqwFr: {defaultValue: "© Implant Ninja 2025 | Contact Us | implantninja@gmail.com • 209-242-2977", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(Framerc9Vcnnpth, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})